import React from "react";
import { Layout, Row, Col } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

import "../styles/centered-box.less";

const { Content } = Layout;

export default function Faq() {
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Foire au question</h2>
            <Row>
              <Col xs={24} style={{ textAlign: "left" }}>
                <h3>Qui est Patinette?</h3>
                <p>
                  Patinette est un courtier d’assurance spécialiste des
                  nouvelles mobilités. Fondé en 2019, nous avons créé pour la
                  communauté des riders le seul pack sécurité intégral, incluant
                  des garanties d'assurance et un forfait maintenance exclusif.
                </p>
                <h3>
                  Quels sont les avantages des packs sécurité de Patinette ?
                </h3>
                <p>
                  Patinette a concu avec les riders le seule offre sécurité
                  intégrale : chez patinette, non seulement vous êtes assurés
                  avec les garanties les plus couvrantes et compétitives du
                  marché, mais en plus vous bénéficiez d’un forfait maintenance
                  et réparation à utiliser comme bon vous semble!
                </p>
                <h3>Quelles sont les garanties des packs sécurité?</h3>
                <p>
                  Les packs sécurité Patinette sont constitués de garanties
                  d'assurance et de services exclusifs 1/ Assurance -
                  Responsabilité civile : c'est la garantie obligatoire ! Elle
                  financera pour vous les dépenses si jamais vous fâites mal à
                  un tiers au volant de votre bolide - Garantie conducteur : ça,
                  c'est notre touche. On s'est dit que c'était bien de protéger
                  les autres, mais que vous aviez droit de penser un peu à vous
                  aussi ! Elle financera toutes les dépenses si jamais vous vous
                  faîtes mal tout seul (par exemple en chutant sur une rue
                  pavée...on dit rien, mais on voit ca tous les jours! - Vol /
                  Casse : on vous paie les réparations matérielles sur votre
                  engin en cas d'accident, et on vous en offre un nouveau si
                  vous vous le faites voler ! 2/ Services - Maintenance et
                  entretien : notre offre est la seule sur le marché à proposer
                  un service de maintenance annuel, chez le spécialiste de votre
                  choix ou au sein de notre réseau de partenaires - SOS
                  Fourrière: votre engin a disparu? Pas de problème, on vous le
                  retrouve en deux coups de cuiller à pot! - Support de
                  téléphone : parce que le téléphone au volant est le premier
                  facteur d'accidents, avec Patinette vous serez entre de bonnes
                  mains!
                </p>
                <h3>Combien coûte le pack sécurité de Patinette?</h3>
                <p>
                  Pour vous simplifier la vie, nous avonc concu 3 packs à prix
                  serré: le pack “Droit de rouler” à seulement 5€/mois, vous
                  permettant de respecter l’obligation d’assurance
                  responsabilité civile ; Le pack “Free Rider“ ajoutant une
                  garantie du conducteur ainsi qu’un forfait maintenance, pour
                  15€/mois; Et le pack “Esprit tranquille” comportant une
                  assurance casse et vol, dès 30€/mois.
                </p>
                <h3>Comment puis je payer mon abonnement?</h3>
                <p>
                  Vous serez prélevés de 2 mois d’abonnement lors de votre
                  inscription, puis vous serez prélevé chaque mois sur le compte
                  que vous avez indiqués.
                </p>
                <h3>
                  L’assurance des engins électriques est elle obligatoire?
                </h3>
                <p>
                  Les engins électriques sont considérés comme un Engin de
                  Déplacement Personnel, et est soumis à l'obligation
                  d’assurance Responsabilité Civile. Ceux qui ignorent la loi
                  sont responsables sur leurs fonds propres en cas d’accident
                  avec autrui, et même passibles de prison.
                </p>
                <h3>
                  Mon assurance habitation me couvre t elle sur mon engin
                  electrique?
                </h3>
                <p>
                  Aucune assurance habitation analysée par Patinette n’inclut la
                  protection de votre engin électrique. De plus, l'immense
                  majorité des assureurs refusent d’assurer en tant que tel un
                  engin. Pas de doute, ayez le réflexe Patinette!
                </p>
                <h3>
                  Mon assurance engin électrique me protège t elle forcément en
                  cas de blessure?
                </h3>
                <p>
                  Absolument pas. Toutes les assurances des engins électriques
                  incluent l’assurance Responsabilité Civile, qui protège les
                  personnes que vous croisez au guidon de votre engin. En
                  revanche, la garantie du conducteur n’est pas une obligation
                  légale. Y souscrire peut néanmoins changer le cours de votre
                  vie!
                </p>
                <h3>Dois je porter un casque au volant de mon bolide?</h3>
                <p>
                  Même si le casque n’est pas obligatoire, il nous semble
                  impératif chez Patinette. Pensez à sortir protégé en vous
                  munissant d’un casque léger type vélo.
                </p>
                <h3>
                  Quelles sont les blessures les plus fréquentes en engin
                  électrique?
                </h3>
                <p>
                  Plus de 60% des accidents au guidon des engins électriques
                  concernent la tête des conducteurs, en raison de “l’effet
                  soleil” crée par le guidon. Méfiez vous des pavés, des
                  chaussées glissantes et des autres usagers de la route!
                </p>
                <h3>Comment me désinscrire ?</h3>
                <p>
                  Vous n’avez plus d'engin électrique et souhaitez interrompre
                  votre assurance? Pas de problème, rendez vous sur votre espace
                  personnel et annulez votre souscription en trois clics.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
